<template>
  <div class="vol-page">
    <div class="vol-linear"></div>
    <div class="container">
      <div class="vol-banner">
        <span v-if="type == 1">省级专项活动</span>
        <span v-if="type == 2">地市精彩活动</span>
        <span v-if="type == 3">找活动</span>
        <img src="../../assets/volunteer/hearts.png" class="vol-hearts" />
      </div>
      <div class="content-wrap">
        <nav aria-label="breadcrumb" v-if="type == 1">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              省级专项活动
            </li>
          </ol>
        </nav>
        <nav aria-label="breadcrumb" v-if="type == 2">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              地市精彩活动
            </li>
          </ol>
        </nav>
        <nav aria-label="breadcrumb" v-if="type == 3">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">找活动</li>
          </ol>
        </nav>
        <!-- 选项卡 -->
        <ul
          class="nav nav-pills nav-orange"
          id="myTab"
          role="tablist"
          v-if="type == 2"
        >
          <li
            class="nav-item"
            role="presentation"
            v-for="item in areaList"
            :key="item.area_id"
          >
            <button
              :class="[
                'nav-link',
                curArea.area_id == item.area_id ? 'active' : '',
              ]"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
              @click="toChangeCity(item)"
            >
              {{ item.area_name }}
            </button>
          </li>
        </ul>
        <div class="tool-top">
          <div class="filter-box" v-if="type == 3">
            <select
              class="form-select"
              aria-label="Default select example"
              @change="toChangeSort($event)"
            >
              <option value="distance" selected>按距离最近</option>
              <option value="start_time">按时间最近</option>
            </select>
            <select
              class="form-select"
              aria-label="Default select example"
              @change="toChangeArea"
            >
              <option value="" selected>按地区</option>
              <option
                :value="item.area_id"
                v-for="item in areaList"
                :key="item.area_id"
              >
                {{ item.area_name }}
              </option>
            </select>
            <select
              class="form-select"
              aria-label="Default select example"
              @change="toChangeService"
            >
              <option value="" selected>按服务类别</option>
              <option
                :value="item"
                v-for="(item, index) in serviceList"
                :key="index"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <SearchBar @toSearch="toSearch"></SearchBar>
        </div>
        <div class="vol-news-list">
          <div
            class="vol-news-item"
            v-for="item in data.list"
            :key="item.event_id"
            @click="toEventDetail(item)"
          >
            <div class="vol-news-pic">
              <img :src="item.image[0]" />
            </div>
            <span class="vol-news-title">{{ item.title }}</span>
            <span class="vol-news-area"
              ><i class="iconfont icon-weibiaoti-3"></i>
              {{ item.city + item.district + item.address }}</span
            >
            <span class="vol-news-time"
              ><i class="iconfont icon-shijian"></i> {{ item.created_at }}</span
            >
          </div>
        </div>
        <div class="none-data" v-if="data.list.length == 0">
          <img src="../../assets/none.png" class="img-none" />
        </div>
        <Pagination
          @change="pageChanged"
          :current="1"
          :pageSize="10"
          :hideOnSinglePage="true"
          :total="parseInt(data.total)"
          :showQuickJumper="false"
          :showTotal="false"
          placement="center"
        />
        <div class="fixed-bottom">
          <router-link
            class="bottom-btn"
            v-if="type == 3"
            :to="{ name: 'volRequire' }"
            >发需求</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { eventList, getBaseData, areaList } from "@/network/API.js";
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/Pagination.vue";
import axios from "axios";
export default {
  components: {
    SearchBar,
    Pagination,
  },
  data() {
    return {
      data: {},
      type: "",
      pageNumber: 1,
      searchKey: "",
      selService: "",
      selArea: {}, //按地区 type==3
      curArea: {}, //地市级 type==2
      serviceList: [],
      areaList: [],
      selSort: "",
      latitude: 0,
      longitude: 0,
    };
  },
  created() {
    this.getUserPosition();
    if (this.$route.params.type) {
      this.type = this.$route.params.type;
    }
    if (this.type == 3) {
      this.getServiceList();
    }
    if (this.type != 1) {
      this.getAreaList();
    }
    this.getEventList();
  },
  methods: {
    getUserPosition() {
      const url =
        "https://apis.map.qq.com/ws/location/v1/ip?ip=111.206.145.41&key=BINBZ-EKWHN-XMFF7-SNNTX-GFG6H-EZFYD";
      this.$jsonp(url, {
        output: "jsonp",
      }).then((res) => {
        let data = res.result.location;
        this.longitude = data.lng;
        this.latitude = data.lat;
        this.pageNumber = 1;
        this.getEventList();
      });
    },
    async getServiceList() {
      let data = await getBaseData("service_type");
      this.serviceList = data;
    },
    async getAreaList() {
      let data = await areaList({
        code: "130000",
      });
      if (this.type == 2) {
        data.splice(0, 0, {
          area_id: "130000",
          area_name: "推荐",
        });
        this.curArea = data[0];
      }
      this.areaList = data;
    },
    async getEventList() {
      var cityCode = "";
      // (this.eventType == 3 ? this.selArea.area_id : this.curArea.area_id) || ""
      if (this.type == 3) {
        cityCode = this.selArea;
      }
      if (this.type == 2) {
        cityCode = this.curArea.area_id;
        if (this.curArea.area_id == "130000") {
          cityCode = "";
        }
      }
      let data = await eventList({
        page_size: 24,
        page: this.pageNumber,
        region_type: this.type,
        keyword: this.searchKey,
        industry: this.selService,
        city_code: cityCode,
        sort: this.selSort,
        latitude: this.latitude,
        longitude: this.longitude,
      });
      data.list.forEach((item) => {
        item.image = item.image.split(",");
      });
      this.data = data;
    },
    toSearch(val) {
      this.searchKey = val;
      this.pageNumber = 1;
      this.getEventList();
    },
    pageChanged(val) {
      this.pageNumber = val.page;
      this.getEventList();
    },
    toChangeArea(e) {
      if (this.selArea == e.target.value) return;
      this.selArea = e.target.value;
      this.pageNumber = 1;
      this.getEventList();
    },
    toChangeService(e) {
      if (this.selService == e.target.value) return;
      this.selService = e.target.value;
      this.pageNumber = 1;
      this.getEventList();
    },
    toChangeCity(item) {
      if (this.curArea.area_id == item.area_id) return;
      this.curArea = item;
      this.pageNumber = 1;
      this.getEventList();
    },
    toChangeSort(item) {
      this.selSort = item.currentTarget.value;
      this.pageNumber = 1;
      this.getEventList();
    },
    toEventDetail(item) {
      this.$router.push({
        name: "EventDetail",
        params: {
          type: this.type,
          id: item.event_id,
        },
      });
    },
  },
};
</script>
<style>
.vol-banner {
  height: 280px;
}

.vol-hearts {
  top: -210px;
}
.tool-top {
  position: relative;
  height: 42px;
}
.search-box {
  top: 0;
}
.search {
  background: linear-gradient(
    96deg,
    rgba(255, 185, 144, 0.1) 0%,
    rgba(255, 50, 65, 0.1) 100%
  );
}
/* 选项卡 */
.nav-orange {
  margin-bottom: 20px;
}
.nav-pills.nav-orange .nav-link {
  margin: 0;
  margin-right: 20px;
  padding-left: 30px;
  padding-right: 30px;
  color: #333;
}

.nav-pills.nav-orange .nav-link:hover {
  color: #ff3241;
}

.nav-pills.nav-orange .nav-link.active {
  background: linear-gradient(118deg, #ffb990 -28%, #ff3241 71%);
  color: #fff;
  border-radius: 50px;
  border: none;
}

.vol-news-list {
  margin: 30px 0;
}
.bottom-btn {
  background: linear-gradient(97deg, #ffb990 0%, #ff3241 100%);
}
</style>
